import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  Typography,
  alpha,
} from "@mui/material";
import type { Coupon } from "@trainwell/features/legacy";
import { useRenewSubscription } from "@trainwell/features/subscriptions";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { useSubscriptionGroupOptions } from "src/hooks/useSubscriptionGroupOptions";
import { hideChat, showChat } from "src/lib/frontChat";
import { formatCentsToDollars } from "src/lib/misc";
import { api } from "src/lib/trainwellApi";
import { fetchTrainer } from "src/slices/trainerSlice";

type Props = {
  open: boolean;
  onClose: () => void;
};

export function RenewDialog({ open, onClose }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const subscriptionOptions = useSubscriptionGroupOptions();
  const [selectedPlan, setSelectedPlan] = useState(1);
  const client = useAppSelector((state) => state.client.client);
  const trainer = useAppSelector((state) => state.trainer.trainer);
  const trainerStatus = useAppSelector((state) => state.trainer.status);
  const couponId = sessionStorage.getItem("couponId");
  const [coupon, setCoupon] = useState<Coupon | null>(null);
  const renewSubscription = useRenewSubscription();

  const currentSubscription = subscriptionOptions?.at(selectedPlan);

  let nextBillPrice = currentSubscription?.monthly_price ?? 0;

  if (currentSubscription?.interval === "month") {
    nextBillPrice =
      currentSubscription.monthly_price * currentSubscription.interval_count;
  } else if (currentSubscription?.interval === "year") {
    nextBillPrice =
      currentSubscription.monthly_price *
      currentSubscription.interval_count *
      12;
  }

  let discountAmountOff: number | null = null;

  if (coupon) {
    if (coupon.amount_off) {
      discountAmountOff = coupon.amount_off;
    } else if (coupon.percent_off) {
      discountAmountOff = nextBillPrice * coupon.percent_off;
    }
  }

  useEffect(() => {
    if (client?.trainer_id && trainerStatus === "idle") {
      dispatch(fetchTrainer(client.trainer_id));
    }
  }, [client?.trainer_id, trainerStatus, dispatch]);

  useEffect(() => {
    if (!coupon && couponId) {
      api.coupons.findOne(couponId).then((data) => {
        setCoupon(data.coupon);
      });
    }
  }, [couponId, coupon]);

  useEffect(() => {
    if (open) {
      hideChat();
    } else {
      showChat({
        clientName: client?.full_name ?? "",
        userId: client?.user_id ?? "",
      });
    }
  }, [open]);

  function handleClose() {
    if (renewSubscription.isPending) {
      return;
    }

    onClose();
    renewSubscription.reset();
  }

  const trainerNoLongerWithCompany =
    !trainer?.is_employee && !trainer?.is_staff;

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Renew plan</DialogTitle>
      <DialogContent>
        {subscriptionOptions.map((subOption, i) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              my: 1,
            }}
            key={i}
          >
            <Box display="flex" alignItems="center">
              <Radio
                checked={selectedPlan === i}
                onChange={() => {
                  setSelectedPlan(i);
                }}
                value={i}
              />
              <Typography>{subOption.title}</Typography>
            </Box>
            <Box sx={{ textAlign: "end" }}>
              <Typography>
                {formatCentsToDollars(subOption.monthly_price * 100)}/mo
              </Typography>
              <Typography variant="caption">{subOption.details}</Typography>
            </Box>
          </Box>
        ))}
        {!client?.account.dashboard.date_onboarded && (
          <Typography
            sx={{
              textAlign: "right",
              fontWeight: "bold",
              color: "green",
            }}
          >
            Your 14 day free trial starts when you call your trainer
          </Typography>
        )}
        {discountAmountOff && (
          <Typography
            sx={{
              textAlign: "right",
              fontWeight: "bold",
              color: "green",
            }}
          >
            Discount Applied: {formatCentsToDollars(discountAmountOff)} off{" "}
            {coupon?.duration === "once"
              ? "first payment"
              : coupon?.duration === "forever"
                ? "every payment forever"
                : `every payment for ${coupon?.duration_in_months} months`}
          </Typography>
        )}
        {trainerNoLongerWithCompany && (
          <Card
            variant="outlined"
            sx={{
              backgroundColor: (theme) =>
                alpha(theme.palette.primary.main, 0.1),
              p: 1,
              my: 2,
              display: "flex",
            }}
          >
            <WarningRoundedIcon color="primary" sx={{ mr: 1 }} />
            <Typography>
              Your trainer, {trainer?.first_name}, is no longer with trainwell.
              You&apos;ll be asked to choose someone new after renewing.
            </Typography>
          </Card>
        )}
        {renewSubscription.isError && (
          <Card
            sx={{
              px: 2,
              py: 0.5,
              my: 2,
              backgroundColor: (theme) => alpha(theme.palette.error.main, 0.2),
              borderColor: (theme) => theme.palette.error.main,
              display: "flex",
              alignItems: "center",
            }}
            variant="outlined"
          >
            <WarningRoundedIcon fontSize="inherit" color="error" />
            <Typography sx={{ ml: 1 }}>
              Something went wrong. Please try again or contact support
              support@trainwell.net.
            </Typography>
          </Card>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            handleClose();
          }}
          disabled={renewSubscription.isPending}
        >
          Close
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            if (!subscriptionOptions) {
              return;
            }

            renewSubscription.mutate(
              {
                data: {
                  userId: client?.user_id ?? "",
                  priceId: subscriptionOptions[selectedPlan].stripe_price_id,
                  couponId: couponId ?? undefined,
                },
              },
              {
                onSuccess: () => {
                  if (trainerNoLongerWithCompany) {
                    navigate("/change-coach");
                  } else {
                    handleClose();
                  }
                },
              },
            );
          }}
          loading={renewSubscription.isPending}
        >
          Renew
        </Button>
      </DialogActions>
    </Dialog>
  );
}
